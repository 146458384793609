// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-es-canales-whatsapp-js": () => import("./../../../src/pages/es/canales/whatsapp.js" /* webpackChunkName: "component---src-pages-es-canales-whatsapp-js" */),
  "component---src-pages-es-crear-cuenta-js": () => import("./../../../src/pages/es/crear-cuenta.js" /* webpackChunkName: "component---src-pages-es-crear-cuenta-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-es-integraciones-freshdesk-js": () => import("./../../../src/pages/es/integraciones/freshdesk.js" /* webpackChunkName: "component---src-pages-es-integraciones-freshdesk-js" */),
  "component---src-pages-es-integraciones-generica-js": () => import("./../../../src/pages/es/integraciones/generica.js" /* webpackChunkName: "component---src-pages-es-integraciones-generica-js" */),
  "component---src-pages-es-integraciones-gvamax-js": () => import("./../../../src/pages/es/integraciones/gvamax.js" /* webpackChunkName: "component---src-pages-es-integraciones-gvamax-js" */),
  "component---src-pages-es-integraciones-hubspot-js": () => import("./../../../src/pages/es/integraciones/hubspot.js" /* webpackChunkName: "component---src-pages-es-integraciones-hubspot-js" */),
  "component---src-pages-es-integraciones-tecnom-js": () => import("./../../../src/pages/es/integraciones/tecnom.js" /* webpackChunkName: "component---src-pages-es-integraciones-tecnom-js" */),
  "component---src-pages-es-integraciones-zendesk-js": () => import("./../../../src/pages/es/integraciones/zendesk.js" /* webpackChunkName: "component---src-pages-es-integraciones-zendesk-js" */),
  "component---src-pages-es-integraciones-zoho-js": () => import("./../../../src/pages/es/integraciones/zoho.js" /* webpackChunkName: "component---src-pages-es-integraciones-zoho-js" */),
  "component---src-pages-es-legal-privacy-js": () => import("./../../../src/pages/es/legal/privacy.js" /* webpackChunkName: "component---src-pages-es-legal-privacy-js" */),
  "component---src-pages-es-legal-terms-js": () => import("./../../../src/pages/es/legal/terms.js" /* webpackChunkName: "component---src-pages-es-legal-terms-js" */),
  "component---src-pages-es-precios-js": () => import("./../../../src/pages/es/precios.js" /* webpackChunkName: "component---src-pages-es-precios-js" */),
  "component---src-pages-es-productos-chatbot-whatsapp-js": () => import("./../../../src/pages/es/productos/chatbot-whatsapp.js" /* webpackChunkName: "component---src-pages-es-productos-chatbot-whatsapp-js" */),
  "component---src-pages-es-productos-chatgpt-para-ventas-js": () => import("./../../../src/pages/es/productos/chatgpt-para-ventas.js" /* webpackChunkName: "component---src-pages-es-productos-chatgpt-para-ventas-js" */),
  "component---src-pages-es-productos-marketing-digital-whatsapp-js": () => import("./../../../src/pages/es/productos/marketing-digital-whatsapp.js" /* webpackChunkName: "component---src-pages-es-productos-marketing-digital-whatsapp-js" */),
  "component---src-pages-es-productos-reportes-whatsapp-js": () => import("./../../../src/pages/es/productos/reportes-whatsapp.js" /* webpackChunkName: "component---src-pages-es-productos-reportes-whatsapp-js" */),
  "component---src-pages-es-productos-whatsapp-multiagente-js": () => import("./../../../src/pages/es/productos/whatsapp-multiagente.js" /* webpackChunkName: "component---src-pages-es-productos-whatsapp-multiagente-js" */),
  "component---src-pages-es-recursos-webinars-abril-2023-con-gvamax-js": () => import("./../../../src/pages/es/recursos/webinars/abril-2023-con-gvamax.js" /* webpackChunkName: "component---src-pages-es-recursos-webinars-abril-2023-con-gvamax-js" */),
  "component---src-pages-es-recursos-webinars-febrero-2023-con-red-7-js": () => import("./../../../src/pages/es/recursos/webinars/febrero-2023-con-red-7.js" /* webpackChunkName: "component---src-pages-es-recursos-webinars-febrero-2023-con-red-7-js" */),
  "component---src-pages-es-recursos-webinars-js": () => import("./../../../src/pages/es/recursos/webinars.js" /* webpackChunkName: "component---src-pages-es-recursos-webinars-js" */),
  "component---src-pages-es-soluciones-concesionarios-js": () => import("./../../../src/pages/es/soluciones/concesionarios.js" /* webpackChunkName: "component---src-pages-es-soluciones-concesionarios-js" */),
  "component---src-pages-es-soluciones-e-commerce-js": () => import("./../../../src/pages/es/soluciones/e-commerce.js" /* webpackChunkName: "component---src-pages-es-soluciones-e-commerce-js" */),
  "component---src-pages-es-soluciones-inmobiliarias-js": () => import("./../../../src/pages/es/soluciones/inmobiliarias.js" /* webpackChunkName: "component---src-pages-es-soluciones-inmobiliarias-js" */),
  "component---src-pages-es-soluciones-servicio-al-cliente-js": () => import("./../../../src/pages/es/soluciones/servicio-al-cliente.js" /* webpackChunkName: "component---src-pages-es-soluciones-servicio-al-cliente-js" */),
  "component---src-pages-es-soluciones-ventas-online-js": () => import("./../../../src/pages/es/soluciones/ventas-online.js" /* webpackChunkName: "component---src-pages-es-soluciones-ventas-online-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("./../../../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-products-chatgpt-for-sales-js": () => import("./../../../src/pages/products/chatgpt-for-sales.js" /* webpackChunkName: "component---src-pages-products-chatgpt-for-sales-js" */),
  "component---src-pages-products-shared-whatsapp-inbox-js": () => import("./../../../src/pages/products/shared-whatsapp-inbox.js" /* webpackChunkName: "component---src-pages-products-shared-whatsapp-inbox-js" */),
  "component---src-pages-products-whatsapp-chatbot-js": () => import("./../../../src/pages/products/whatsapp-chatbot.js" /* webpackChunkName: "component---src-pages-products-whatsapp-chatbot-js" */),
  "component---src-pages-products-whatsapp-marketing-js": () => import("./../../../src/pages/products/whatsapp-marketing.js" /* webpackChunkName: "component---src-pages-products-whatsapp-marketing-js" */),
  "component---src-pages-products-whatsapp-reports-js": () => import("./../../../src/pages/products/whatsapp-reports.js" /* webpackChunkName: "component---src-pages-products-whatsapp-reports-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */)
}

